import { CSSProperties, FC, memo } from 'react';
import { StarFilled } from '@ant-design/icons';

const COUNT = 5;
const DEFAULT_COLOR = 'rgba(0, 0, 0, 0.06)';
const DEFAULT_STYLE = {
	color: DEFAULT_COLOR,
};
const HIGHLIGHT_COLOR = '#fadb14';
const HIGHLIGHT_STYLE = {
	color: HIGHLIGHT_COLOR,
};

const getIntStyle = (value: number, int: number, index: number): CSSProperties =>
	value >= COUNT || index < int ? HIGHLIGHT_STYLE : DEFAULT_STYLE;

const getDecimalWrapperStyle = (decimal: number): CSSProperties => ({
	width: `${decimal * 100}%`,
});

export interface RateProps {
	className?: string;
	style?: CSSProperties;
	value?: number | string | null;
}

/**
 * LTL 搜索运价步骤二的评分组件，5 分满分，支持小数点后两位。
 */
export const Rate: FC<RateProps> = memo(({ value, className, style }) => {
	const label = value == null ? '--' : Number(Number(value).toFixed(2));
	const _value = Number(Number(value ?? 0).toFixed(2));
	const int = Math.floor(_value);
	const decimal = _value - int;

	return (
		<div className={`flex items-center justify-start gap-2 ${className}`} style={style}>
			{Array.from({ length: COUNT }).map((_, index) => (
				<div key={index} className='relative'>
					<StarFilled style={getIntStyle(_value, int, index)} />
					{index === int && index < _value && (
						<div
							className='absolute top-0 left-0 overflow-hidden h-full'
							style={getDecimalWrapperStyle(decimal)}
						>
							<StarFilled style={HIGHLIGHT_STYLE} />
						</div>
					)}
				</div>
			))}
			<span className='h3-sb text-black'>{label}</span>
		</div>
	);
});
